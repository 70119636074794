import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { AppDesignDTO } from 'app/model/entity/ticket-editor/dto/app-design-DTO';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
@Component({
  selector: 'dialog-translate-ticket',
  templateUrl: './dialog-translate-ticket.component.html',
  styleUrls: ['./dialog-translate-ticket.component.scss']
})
export class DialogTranslateTicketComponent implements OnInit {
  languages: any = new Array<any>();
  languageKey: string;
  appNameEdit: string;
  applicationCloneDeep: ApplicationDTO;
  numbers = Array.from({ length: 11 }, (_, i) => parseFloat((i * 0.1).toFixed(1)));

  /**
   * common object
   */
  private commonObject: Common;
  /**
   * languagesCode
   */
  public languagesCode: string[] = [];

  formApp: FormGroup;
  isSubmitted: boolean = false;

  Constant = Constant;

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataTranslation,
    private dialogRef: MatDialogRef<DialogTranslateTicketComponent>,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private ticketService: TicketEditorService
  ) {
    this.commonObject = commonService.getCommonObject();
    this.translateService.onLangChange.subscribe(() => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
    });

    if (this.commonObject.setting?.languagesSetting) {
      this.languagesCode = this.commonObject.setting?.languagesSetting.includes('"')
        ? this.commonObject.setting?.languagesSetting
            .slice(1, -1)
            .split(',')
            .filter(item => item.trim() !== '')
        : this.commonObject.setting?.languagesSetting.split(',').filter(item => item.trim() !== '');
    }

    this.translateService.onLangChange.subscribe(() => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
      this.specifyTheTranslationLanguage();
    });
  }

  ngOnInit(): void {
    this.languageKey = this.commonService.getCommonObject()?.setting?.language;
    this.specifyTheTranslationLanguage();
    this.initFormApp();
    if (this.data.mode == Constant.MODE_EDIT) {
      this.applicationCloneDeep = _.cloneDeep(this.data.application);
      this.formApp.patchValue({
        appName: this.data.application?.appName,
        shortNameOfTheApp: this.data.application.appNameShort,
        appUrl: this.data.application?.appUrl,
        appHomepageUrl: this.data.application?.appHomepageUrl,
        userPoolId: this.data.application?.userPoolId
      });
    }
  }

  ngOnDestroy(): void {
    this.data.application = null;
    this.data.mode = null;
    this.applicationCloneDeep = undefined;
  }

  specifyTheTranslationLanguage() {
    const defaultLanguage = this.languageKey == 'en' ? 'en' : 'ja';
    const index = this.languagesCode.indexOf(defaultLanguage);
    if (index == -1) {
      this.languagesCode.unshift(defaultLanguage);
    } else {
      this.languagesCode.splice(index, 1);
      this.languagesCode.unshift(defaultLanguage);
    }
    this.languagesCode.forEach(element => {
      const index = Constant.LANGUAGES_SETTING.findIndex(e => e.translation_language_code == element);
      this.languages.push(Constant.LANGUAGES_SETTING[index]);
    });
    if (this.data?.application.supportedLanguage && this.data?.application.supportedLanguage.length) {
      this.languages.forEach(language => {
        if (this.data?.application.supportedLanguage.includes(language.translation_language_code)) {
          language.isChecked = true;
        } else {
          language.isChecked = false;
        }
      });
    } else {
      this.languages.forEach(language => {
        language.isChecked = false;
      });
    }
    this.languages[0].isChecked = true;
  }

  /**
   * change Checked Language
   * @param language
   */
  changeCheckedLanguage(language: any): void {
    language.isChecked = !language.isChecked;
  }

  /**
   * translate
   */
  saveApp(): void {
    this.isSubmitted = true;
    if (this.formApp.valid) {
      let result = this.languages.filter(e => e?.isChecked).map(e => e.translation_language_code);
      let payload = {
        appName: this.formApp.value?.appName,
        companyId: +this.data?.informationAccount?.compnanyid,
        shortName: this.formApp.value?.shortNameOfTheApp,
        languages: result,
        appUrl: this.formApp.value?.appUrl,
        appHomepageUrl: this.formApp.value?.appHomepageUrl,
        userPoolId: this.formApp.value?.userPoolId
      };

      if (!this.data.application.appId) {
        // mode Add
        this.ticketService.createApplication(this.data?.informationAccount, payload).subscribe(res => {
          this.dialogRef.close(res);
        });
      } else {
        payload['appId'] = this.data.application.appId;
        if (
          this.applicationCloneDeep.appName == this.formApp.value?.appName &&
          this.applicationCloneDeep.appNameShort == this.formApp.value?.shortNameOfTheApp &&
          this.applicationCloneDeep.appUrl == this.formApp.value?.appUrl &&
          this.applicationCloneDeep.appHomepageUrl == this.formApp.value?.appHomepageUrl &&
          this.applicationCloneDeep.userPoolId == this.formApp.value?.userPoolId &&
          JSON.stringify(this.applicationCloneDeep.supportedLanguage) == JSON.stringify(result)
        ) {
          this.dialogRef.close(undefined);
        } else {
          if (JSON.stringify(this.applicationCloneDeep.supportedLanguage) !== JSON.stringify(result)) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  title: this.translateService.instant('dialog-translate-ticket.title-confirm'),
                  text: this.translateService.instant('dialog-translate-ticket.change-language'),
                  button1: this.translateService.instant('dialog-translate-ticket.save'),
                  button2: this.translateService.instant('dialog-translate-ticket.cancel')
                }
              },
              res => {
                if (!res) {
                  return;
                }
                this.ticketService.updateApplication(this.data.informationAccount, payload).subscribe(res => {
                  res['appDesignIdSelected'] = this.data.appDesignSelected.appDesignId;
                  this.dialogRef.close(res);
                });
              }
            );
          } else {
            this.ticketService.updateApplication(this.data.informationAccount, payload).subscribe(res => {
              res['appDesignIdSelected'] = this.data.appDesignSelected.appDesignId;
              this.dialogRef.close(res);
            });
          }
        }
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  initFormApp(): void {
    this.formApp = this.fb.group({
      appName: ['', [this.noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(255)]],
      shortNameOfTheApp: ['', Validators.maxLength(255)],
      appUrl: '',
      appHomepageUrl: '',
      userPoolId: ''
    });
  }

  /**
   * noWhitespaceValidator
   * @param control
   * @returns
   */
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  /**
   * get form Control
   */
  get f() {
    return this.formApp.controls;
  }
}

export interface DialogDataTranslation {
  title: string;
  application: ApplicationDTO;
  mode: number;
  informationAccount: any;
  appDesignSelected: AppDesignDTO;
}
