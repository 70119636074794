import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { CommonTable } from 'app/model/entity/commonTable';
import { TimetableUpdateTiming } from 'app/model/entity/timetable-update-timing';
import { CommonTableService } from 'app/service/common-table.service';
import { DialogService } from 'app/service/dialog.service';

@Component({
  selector: 'dialog-schedule-merge-update-timing',
  templateUrl: './dialog-schedule-merge-update-timing.component.html',
  styleUrls: ['./dialog-schedule-merge-update-timing.component.scss']
})
export class DialogScheduleMergeUpdateTimingComponent implements OnInit {
  /**
   * constant
   */
  Constant = Constant;
  /**
   * timetable Update Timing
   */
  timetableUpdateTiming: TimetableUpdateTiming;
  /**
   * timetable Update Timing Form
   */
  timetableUpdateTimingForm: FormGroup;
  /**
   * commonTable
   */
  commonTable: CommonTable;

  constructor(
    public dialogRef: MatDialogRef<DialogScheduleMergeUpdateTimingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonTableService: CommonTableService
  ) {}

  ngOnInit(): void {
    this.commonTable = this.data.commonTable;
    this.timetableUpdateTimingForm = this.formBuilder.group({
      seconds: [+this.commonTable.value, [Validators.required, Validators.max(Constant.MAX_TIME_SECONDS)]]
    });
  }

  /**
   * save
   */
  public save(): void {
    if (this.timetableUpdateTimingForm.invalid) {
      return;
    }
    this.commonTable.value = this.timetableUpdateTimingForm.value.seconds;
    this.commonTableService.save(this.commonTable).subscribe(
      () => {
        this.dialogRef.close(this.commonTable.value);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close(undefined);
  }
}

/**
 * interface DialogData
 */
export interface DialogData {
  commonTable: CommonTable;
}
