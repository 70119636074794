import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { CommonTable } from 'app/model/entity/commonTable';
import { TimetableUpdateTiming } from 'app/model/entity/timetable-update-timing';
import { CommonTableService } from 'app/service/common-table.service';
import { DialogService } from 'app/service/dialog.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'dialog-timetable-update-timming',
  templateUrl: './dialog-timetable-update-timming.component.html',
  styleUrls: ['./dialog-timetable-update-timming.component.scss']
})
export class DialogTimetableUpdateTimingComponent implements OnInit {
  /**
   * constant
   */
  Constant = Constant;
  /**
   * timetable Update Timing
   */
  timetableUpdateTiming: TimetableUpdateTiming;
  /**
   * timetable Update Timing Form
   */
  timetableUpdateTimingForm: FormGroup;
  /**
   * commonTable
   */
  commonTable: CommonTable;
  commonTableIsBack: CommonTable;

  constructor(
    public dialogRef: MatDialogRef<DialogTimetableUpdateTimingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonTableService: CommonTableService
  ) {}

  ngOnInit(): void {
    this.commonTable = this.data.commonTable;
    this.commonTableIsBack = this.data.commonTableIsBack;
    const isBack = this.commonTableIsBack.value ?? 'false';
    const seconds = isBack == 'true' ? +this.commonTable.value * -1 : +this.commonTable.value;
    this.timetableUpdateTimingForm = this.formBuilder.group({
      seconds: [seconds, [Validators.required, Validators.max(Constant.MAX_TIME_SECONDS_UPDATE_TIMETABLE)]],
      isBack: [isBack]
    });
  }

  /**
   * save
   */
  public save(): void {
    if (this.timetableUpdateTimingForm.invalid) {
      return;
    }
    this.commonTable.value =
      this.timetableUpdateTimingForm.value.isBack == 'true'
        ? +this.timetableUpdateTimingForm.value.seconds * -1
        : this.timetableUpdateTimingForm.value.seconds;
    this.commonTableIsBack.value = this.timetableUpdateTimingForm.value.isBack;
    forkJoin({
      commonTable: this.commonTableService.save(this.commonTable),
      commonTableIsBack: this.commonTableService.save(this.commonTableIsBack)
    }).subscribe(
      data => {
        this.dialogRef.close(this.commonTable.value);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close(undefined);
  }
}

/**
 * interface DialogData
 */
export interface DialogData {
  commonTable: CommonTable;
  commonTableIsBack: CommonTable;
}
